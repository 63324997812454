<script setup lang="ts">
import { type StyleValue } from 'vue';

import Utility from '~/services/Utility';

type WBImageProps = {
  width: number;
  height: number;
  src: string | null;
  alt: string;
  isS3: boolean;
  customStyle?: StyleValue;
};

const props = withDefaults(defineProps<WBImageProps>(), {
  width: 0,
  height: 0,
  src: null,
  alt: '',
  isS3: false,
  customStyle: undefined,
});

const setStyle = () => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
  };
};
</script>

<template>
  <img v-if="!props.isS3" :src="props.src ?? ''" :alt="props.alt" :style="setStyle()" loading="lazy" />
  <!-- <NuxtImg v-else :src="props.src" :alt="props.alt" :width="props.width" :height="props.height" fit="fill" /> -->
  <img v-else :src="props.src" :alt="props.alt" :width="props.width" :height="props.height" fit="fill" loading="lazy" />
</template>

<style lang="scss" scoped></style>
