<script setup lang="ts">
import { WBImage } from '~/components';

import Utility from '~/services/Utility';

import { useCommunityStore } from '../../../store';

const communityStore = useCommunityStore();

const props = withDefaults(
  defineProps<{
    profileImgUrl: string | null;
    width: number | null;
    height: number | null;
  }>(),
  {
    profileImgUrl: null,
    width: null,
    height: null,
  },
);

const setHasProfile = (): boolean => {
  if (!props.profileImgUrl || props.profileImgUrl === '') return false;
  return true;
};

const setContainerSize = () => {
  return {
    width: `${props.width ?? 32}px`,
    height: `${props.height ?? 32}px`,
  };
};
</script>

<template>
  <div :style="setContainerSize()">
    <WBImage
      :src="
        communityStore.setProfileImg(
          props.profileImgUrl ? Utility.getOptimized(props.profileImgUrl, 'PROFILE_IMG') : '',
        )
      "
      alt="user profile"
      :width="props.width ?? 32"
      :height="props.height ?? 32"
      class="user-profile-img"
      :is-s3="setHasProfile()" />
  </div>
</template>

<style lang="scss" scoped>
.user-profile-img {
  border-radius: 100%;
  object-fit: cover;
}
</style>
